import type { TOptional } from '@/types/common';

export type TBaseMessage = { type: string };
export type TBaseHistoryItem = { time: number };

export interface TFailWatcher<
  TMessage extends TBaseMessage = TBaseMessage,
  THistoryItem extends TBaseHistoryItem = TBaseHistoryItem,
> {
  accept?: (message: TMessage) => TOptional<THistoryItem>;
  analyze?: (history: THistoryItem[]) => TOptional<THistoryItem[]>;
}

export type TWatcherItem = { history: TBaseHistoryItem[]; watcher: TFailWatcher };

export default class FailAnalyzer {
  private watchers: TWatcherItem[] = [];

  constructor(...watchers: TFailWatcher[]) {
    this.addWatcher(...watchers);
  }

  addWatcher(...watchers: TFailWatcher[]): void {
    watchers.forEach((watcher) => {
      if ([watcher?.accept, watcher?.analyze].every((fn) => typeof fn === 'function')) {
        this.watchers.push({ history: [], watcher });
      }
    });
  }

  analyze<M extends TBaseMessage>(message: M): void {
    this.watchers.forEach((item) => {
      const { history, watcher } = item;
      const nextItem = watcher.accept?.(message);
      if (nextItem) {
        const nextHistory = [...history, nextItem];
        item.history = watcher.analyze?.(nextHistory) || nextHistory;
      }
    });
  }

  removeWatcher(...watchers: TFailWatcher[]): void {
    watchers.forEach((watcher) => {
      for (let i = this.watchers.length - 1; i >= 0; i -= 1) {
        if (watcher === this.watchers[i]?.watcher) {
          this.watchers.splice(i, 1);
        }
      }
    });
  }
}
