import { JL } from 'jsnlog';
import { v4 as generateUUID } from 'uuid';

import type { TOptional } from '@/types/common';

import type { TBaseHistoryItem, TBaseMessage, TFailWatcher } from './FailAnalyzer';

// Fail Analyzer Watcher for IAM (Identity Access Management) service

const IAM_REPORT = {
  failed: {
    message: 'IAM service is unavailable',
    messageDetail: {
      message: 'common service request error message',
      messageCode: '9001',
      severity: 'ERROR',
    },
  },
  recovered: {
    message: 'IAM service availability is restored',
    messageDetail: {
      message: 'common service request message',
      messageCode: '5001',
      severity: 'INFO',
    },
  },
};

type TIAMMessage = { success?: boolean } & TBaseMessage;
type TIAMHistoryItem = { success?: boolean } & TBaseHistoryItem;

export default class IAMFailWatcher implements TFailWatcher<TIAMMessage, TIAMHistoryItem> {
  private isFailed: boolean = false;

  constructor(
    private messageType: string,
    private rulePeriod: number,
    private ruleCount: number,
  ) {}

  accept(message: TIAMMessage): TOptional<TIAMHistoryItem> {
    if (message?.type === this.messageType) {
      return { success: message?.success, time: Date.now() };
    }
  }

  analyze(history: TIAMHistoryItem[]): TOptional<TIAMHistoryItem[]> {
    const threshold = Date.now() - 1000 * this.rulePeriod;
    const lastItems = history.filter(({ time }) => threshold <= time);
    if (lastItems.length) {
      if (!lastItems.at(-1)?.success) {
        if (!this.isFailed) {
          const count = lastItems.reduce((acc, { success }) => (!success ? acc + 1 : acc), 0);
          if (this.ruleCount <= count) {
            this.isFailed = true;
            this.report();
          }
        }
      } else if (this.isFailed) {
        this.isFailed = false;
        this.report();
      }
    }
    return lastItems;
  }

  report(): void {
    const correlationId = generateUUID();
    if (this.isFailed) {
      JL('BV').error({ ...IAM_REPORT.failed, correlationId });
    } else {
      JL('BV').info({ ...IAM_REPORT.recovered, correlationId });
    }
  }
}
