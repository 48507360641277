import parseInt from 'lodash/parseInt';

import { getMnvvReservation } from '@/ducks/mnvv/utils';
import { getURLSearchParams } from '@/ducks/routes/history';
import { getBookingSource } from '@/helpers/util/misc';

export default (
  voyageId,
  cabinType,
  sailors,
  cabins,
  currencyCode,
  isCabinAccessible,
  metaType,
  promoCode,
  externalRefId,
) => {
  const cabinsArray = [];
  const guestDetailsArray = [];
  const cabinCount = parseInt(cabins);
  const { isMNVV } = getMnvvReservation(getURLSearchParams());

  for (let i = 1; i <= cabinCount; i += 1) {
    cabinsArray.push({
      accessible: isCabinAccessible ? JSON.parse(isCabinAccessible) : false,
      adultsCount: parseInt(sailors),
      // TODO: [MSH-105817] check do we need this field in any other cases
      // cabinMetaCode: metaType,
      cabinTypeCode: cabinType,
    });
  }
  for (let i = 1; i <= parseInt(sailors); i += 1) {
    const guestDetails = {
      hasOptedForContactForFlights: true,
      hasOptedForTripInsurance: true,
    };
    if (i === 1 && externalRefId) {
      guestDetails.externalRefId = externalRefId;
    }
    guestDetails.guestRefNumber = i.toString();
    guestDetailsArray.push(guestDetails);
  }

  return {
    cabins: cabinsArray,
    guestDetails: guestDetailsArray,
    promoCode: promoCode || undefined,
    voyageId,
    ...getBookingSource(currencyCode),
    ...(isMNVV && { isVoyageUpdate: true }),
  };
};
