import FailAnalyzer from './FailAnalyzer';
import IAMFailWatcher from './IAMFailWatcher';

export const IAM_WATCHER = 'IAM_WATCHER';
export const IAM_WATCHER_PERIOD = 120;
export const IAM_WATCHER_COUNT = 4;

const failAnalyzer = new FailAnalyzer(new IAMFailWatcher(IAM_WATCHER, IAM_WATCHER_PERIOD, IAM_WATCHER_COUNT));

export default failAnalyzer;
