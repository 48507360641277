import {
  getFilterAgency,
  getFilterAgencyCurrencyCode,
  getFilterAgencyIsAvailable,
  getFilterAgent,
} from '@/ducks/fm/getters';

export const buildAgencyQuery = (params) => {
  if (getFilterAgencyIsAvailable()) {
    const { agencyId } = getFilterAgency();
    const { agentId } = getFilterAgent();
    const agentcurrency = getFilterAgencyCurrencyCode();
    return `${params}&agencyId=${agencyId}&agentId=${agentId}&currencyCode=${agentcurrency}`;
  }
  return params;
};

export const generateShareURL = (urlContext, shareType, pkgCode, voyageCode, metaCat) => {
  let params = `${urlContext}?type=${shareType}&pkgCode=${pkgCode}&voyageCode=${voyageCode}&metaCat=${metaCat}`;
  params = buildAgencyQuery(params);
  return params;
};

export const generateExtendedShareURL = (
  urlContext,
  shareType,
  packageCode,
  voyageId,
  referralCode,
  shipCode,
  isCelebrations = true,
) => {
  let params = `${urlContext}?type=${shareType}&pkgCode=${packageCode}&voyageCode=${voyageId}&referralCode=${referralCode}&shipCode=${shipCode}`;
  if (isCelebrations && shareType === 'P') {
    params = `${params}&referralType=CELEBRATIONS`;
  }
  params = buildAgencyQuery(params);
  return params;
};
