import { jwtDecode } from 'jwt-decode';

import failAnalyzer, { IAM_WATCHER } from '@/app/failAnalyzer';
import { env } from '@/environment';
import {
  type AppTokens,
  type FetchTokenArgs,
  type Token,
  type TokenReqOptions,
  type TokenResponse,
  TokenType,
} from '@/helpers/api/tokens/types';
import { isServerSide } from '@/helpers/isServerSide';
import sharedCall from '@/helpers/sharedCall';
import { log } from '@/helpers/util/logger';
import { type TOptional } from '@/types/common';

export const TIME_INVALIDATE = 15; // In seconds

const TOKEN_TYPE_TO_GRANT_TYPES = {
  [TokenType.cms]: 'password',
  [TokenType.guest]: 'client_credentials',
} as const;

class AppAuthenticator {
  private static instance: AppAuthenticator = new AppAuthenticator();
  static getInstance() {
    return AppAuthenticator.instance;
  }

  private tokens: AppTokens = {
    [TokenType.cms]: null,
    [TokenType.guest]: null,
  };

  private getRawToken = (tokenType: TokenType) => {
    return this.tokens[tokenType];
  };

  setRawTokens = (tokens: AppTokens) => {
    this.tokens = tokens;
  };

  private setRawToken = (tokenType: TokenType, token: Token) => {
    this.tokens = { ...this.tokens, [tokenType]: token };
  };

  public getTokenExpirationTime = (token: string): TOptional<number> => {
    if (token) {
      try {
        const { exp } = jwtDecode(token) || {};
        if (typeof exp === 'number') return 1000 * exp;
      } catch {
        /* nothing */
      }
    }
  };

  public isTokenValid = ({ token, tokenType }: { token?: Token | null; tokenType?: TokenType }) => {
    if (!token && !tokenType) {
      return false;
    }

    const { access_token } = token ?? this.getRawToken(tokenType!) ?? {};
    return !!access_token && this.getTokenExpirationTime(access_token)! > Date.now() + TIME_INVALIDATE * 1000;
  };

  private fetchToken = async ({ options, tokenType }: FetchTokenArgs) => {
    const basicToken = btoa(`${env.CLIENT_ID}:${env.CLIENT_SECRET}`);

    const credentials = {
      password: env.CMS_REST_PASSWORD,
      username: env.CMS_REST_USERNAME,
    };

    const url = new URL(`${env.CMS_AUTH_BASE_URL}/oauth/token`);
    const params = {
      grant_type: TOKEN_TYPE_TO_GRANT_TYPES[tokenType],
      ...(tokenType === TokenType.cms && credentials),
    };
    url.search = new URLSearchParams(params).toString();

    const result = await fetch(url.toString(), {
      cache: 'no-store',
      headers: {
        authorization: `Basic ${basicToken}`,
      },
      method: 'POST',
      ...options,
    });

    if (!result.ok) {
      throw new Error(`Got code '${result.status}' for token: '${tokenType}'.\nDetails: ${result.statusText}`);
    }

    failAnalyzer.analyze({ success: result.ok, type: IAM_WATCHER });

    const jsonResult = (await result.json()) as TokenResponse;

    return jsonResult;
  };

  private fetchTokens = async ({ options }: Pick<FetchTokenArgs, 'options'> = {}) =>
    this.loopOverAllTokens({ fn: this.fetchToken, options });

  public getFreshToken = async ({ options, tokenType }: FetchTokenArgs) => {
    const token = this.getRawToken(tokenType);
    if (this.isTokenValid({ token, tokenType })) {
      return token;
    }
    await this.refreshToken({ options, tokenType });
    return this.getRawToken(tokenType);
  };

  public refreshToken = sharedCall(async ({ options, tokenType }: FetchTokenArgs) => {
    const result: Token = isServerSide()
      ? await this.fetchToken({ options, tokenType })
      : await fetch(`${env.CONTEXT}/api/auth?tokenType=${tokenType}`).then((res) => res.json());

    if (result) {
      //////////////////////////////////////////////////
      // Temporary logging for task https://virginvoyages.atlassian.net/browse/MSH-116779
      // TODO: Remove it when task will be completed !!!
      const prev = this.getRawToken(tokenType);
      if (prev?.access_token === result?.access_token) {
        console.error('Auth: Token is not refreshed: prev=', prev, '; next=', result, ';');
        log({
          Details: JSON.stringify({ next: result, prev }),
          'Error Name': 'TokenRefreshError',
          Message: 'Token is not refreshed',
        });
      } else {
        log({ 'Error Name': 'TokenRefreshSuccess', Message: 'Token is refreshed successfully' });
      }
      //////////////////////////////////////////////////
      this.setRawToken(tokenType, result);
    }
  });

  public getFreshAccessToken = async (arg: Parameters<typeof this.getFreshToken>[0]) =>
    this.getFreshToken(arg).then((token) => token?.access_token);

  public getAllFreshTokens = async ({ options }: { options?: TokenReqOptions } = {}) =>
    this.loopOverAllTokens({ fn: this.getFreshToken, options });

  private loopOverAllTokens = async ({
    fn,
    options,
    ...args
  }: {
    fn: (arg: FetchTokenArgs) => Promise<Token | null>;
    options?: TokenReqOptions;
  } & Record<string, unknown>) => {
    const tokenTypes = Object.values(TokenType);
    const tokens = await Promise.all(tokenTypes.map((tokenType) => fn({ options, tokenType, ...args })));

    return tokenTypes.reduce((acc, tokenType, i) => {
      acc[tokenType] = tokens[i]!;
      return acc;
    }, {} as AppTokens);
  };
}

export default AppAuthenticator;
