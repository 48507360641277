import { selectSettings } from '@/ducks/common/settings/selectors';
import { getSearchParams } from '@/ducks/routes/history';
// eslint-disable-next-line import/no-cycle
import { fetchReferralCode, linkReferralData, shortenUrl, validateReferralCode } from '@/helpers/api/app';
import { generateExtendedShareURL } from '@/helpers/social';

import {
  MGM_CLEBERATION_CASE_NOERROR,
  MGM_CLEBERATION_CASE_SHOWERROR,
  MGM_EXIT_REFERRED_BOOKER_FLOW,
  MGM_GENERATE_REFERRAL_URL_FAILURE,
  MGM_GENERATE_REFERRAL_URL_START,
  MGM_GENERATE_REFERRAL_URL_SUCCESS,
  MGM_LINK_REFERRAL_CODE_ERROR,
  MGM_LINK_REFERRAL_CODE_SUCCESS,
  MGM_VALIDATE_REFERRAL_CODE_FAILURE,
  MGM_VALIDATE_REFERRAL_CODE_SUCCESS,
} from './actionTypes';
import { getReferralData } from './helpers';
import { getReferredParams, selectMgm } from './selectors';

export const exitReferredBookerFlowAction = () => async (dispatch, getState) => {
  const { isReferredBooker } = getState().mgm;
  // No need to do anything if ReferredBooker flow is already turned off
  if (!isReferredBooker) {
    return;
  }

  dispatch({ type: MGM_EXIT_REFERRED_BOOKER_FLOW });
};

const validateReferralCodeAction = (referredParams) => async (dispatch) => {
  const { referralCode, voyageId } = referredParams;
  try {
    const validationResult = await validateReferralCode(referralCode);
    if (validationResult && validationResult.voyageId === voyageId) {
      dispatch({
        payload: referredParams,
        type: MGM_VALIDATE_REFERRAL_CODE_SUCCESS,
      });
    } else {
      dispatch({
        payload: referredParams,
        type: MGM_VALIDATE_REFERRAL_CODE_FAILURE,
      });
    }
  } catch (err) {
    dispatch({
      payload: referredParams,
      type: MGM_VALIDATE_REFERRAL_CODE_FAILURE,
    });
  }
};

export const checkReferralCodeAction = () => async (dispatch, getState) => {
  const state = getState();
  const { isMgm } = selectMgm(state);
  const { referralCode, voyageCode } = getReferredParams(state) || {};
  const parsed = getSearchParams();
  if (parsed.referralCode && !parsed.referralType && parsed.referralType !== 'CELEBRATIONS') {
    await dispatch({
      payload: true,
      type: MGM_CLEBERATION_CASE_SHOWERROR,
    });
    return;
  }

  if ((parsed.referralCode && parsed.referralType === 'CELEBRATIONS') || !parsed.referralCode) {
    await dispatch({
      payload: false,
      type: MGM_CLEBERATION_CASE_NOERROR,
    });
    return;
  }

  if (parsed.referralType === 'CELEBRATIONS') {
    return;
  }
  // MGM feature is disabled or no required params in query string
  if (!isMgm || !parsed.referralCode || !parsed.voyageId || !parsed.packageCode) {
    return;
  }

  // referralCode is already checked and memorized
  if (referralCode === parsed.referralCode && voyageCode === parsed.voyageId) {
    return;
  }

  await dispatch(
    validateReferralCodeAction({
      packageCode: parsed.packageCode,
      referralCode: parsed.referralCode,
      voyageId: parsed.voyageId,
    }),
  );
};

// Fetch referralCode by reservation number.
// Build referral url and process it with minifier.
// Store everything in reservationNumber -> {[referral data]} mapping
export const generateReferralUrlAction = (params) => async (dispatch, getState) => {
  const { packageCode, reservationNumber, shipCode, voyageId } = params;
  const state = getState();

  // Do not make duplicate requests
  if (getReferralData(state, reservationNumber)?.loading) {
    return;
  }

  try {
    dispatch({
      payload: { reservationNumber },
      type: MGM_GENERATE_REFERRAL_URL_START,
    });

    const res = await fetchReferralCode(reservationNumber);
    const referralCode = res.referral_code;
    const referralFullUrl = generateExtendedShareURL(
      selectSettings(state)?.shareUrl,
      'P',
      packageCode,
      voyageId,
      referralCode,
      shipCode,
    );
    const referralUrl = await shortenUrl(referralFullUrl);

    dispatch({
      payload: { referralCode, referralUrl, reservationNumber },
      type: MGM_GENERATE_REFERRAL_URL_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: { reservationNumber },
      type: MGM_GENERATE_REFERRAL_URL_FAILURE,
    });
  }
};

// Link referred booker reservation with original booker referral code
export const linkReferredBookerAction = (reservationNumber) => async (dispatch, getState) => {
  try {
    await linkReferralData({
      referralCode: getReferredParams(getState()).referralCode,
      reservationId: reservationNumber,
    });
    dispatch({ type: MGM_LINK_REFERRAL_CODE_SUCCESS });
  } catch (error) {
    dispatch({ type: MGM_LINK_REFERRAL_CODE_ERROR });
  }
};
