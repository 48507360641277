import { createSelector } from '@reduxjs/toolkit';
import get from 'lodash/get';

import { selectSettings } from '@/ducks/common/settings/selectors';

// Selectors
export const getIsCabinUnavailable = (state) => {
  const invoice = get(state, 'voyagePlanner.summary.calculateInvoice.error', {});
  return invoice.isSoldOut || invoice.isSellLimitExceeded;
};

export const selectMgm = createSelector(
  (state) => state.mgm,
  ({ isMgm, isReferralLinkError, isReferredBooker }) => ({
    isMgm,
    isOriginalBooker: isMgm && !isReferredBooker,
    isReferralLinkError,
    isReferredBooker,
    loading: isMgm,
  }),
);

export const getReferredBookerOptions = createSelector(
  (state) => selectMgm(state).isReferredBooker,
  (state) => state?.celebrations?.params?.newReferral,
  (state) => selectSettings(state)?.featureFlags?.celebrations,
  (isReferredBooker, newReferral, celebrations = false) => {
    let program = 'mgm';
    if (newReferral === false) {
      isReferredBooker = celebrations;
      program = 'celebrations';
    }
    return { isReferredBooker, program };
  },
);

export const getReferredParams = (state) => state.mgm.referredParams;
