export type AppTokens = Record<TokenType, Token | null>;
export enum TokenType {
  cms = 'cms',
  guest = 'guest',
}
export type Token = TokenResponse;
export type TokenResponse = {
  access_token: string;
  companyid: string;
  expirationDate: number;
  expires_in: number;
  jti: string;
  scope: string;
  token_type: string; // bearer
  tokenType: string; // clientToken
};

export type TokenReqOptions = Record<string, unknown>;
export type FetchTokenArgs = { options?: TokenReqOptions; tokenType: TokenType };
