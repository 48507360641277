import get from 'lodash/get';
import isObject from 'lodash/isObject';

const isNotEmptyFields = ({ args }) =>
  Object.keys(args).every((field) => {
    let result;
    const isItObject = isObject(args[field]);
    if (isItObject) {
      result = isNotEmptyFields({ args: args[field] });
    } else {
      result = isNotEmpty(args, field);
    }
    return result;
  });

const isRequiredFieldsExist = ({ args, required }) => required.every((field) => isNotEmpty(args, field));

// fields with the undefined value will be ignored in axios (stringify)
const isNotEmpty = (args, field) => get(args, field) !== null;

export default { isNotEmptyFields, isRequiredFieldsExist };
