// TODO: better way to pass traceId

const actionType = 'DTTraceId::';

let traceIdSended = false; // send once, catch once
export const passTraceIdToErrorStream = (traceId) => {
  if (traceIdSended) return;

  console.error(actionType, traceId);
  traceIdSended = true;
};

export const takeTraceIdFromErrorStream = (data) => {
  if (data?.includes(actionType)) {
    const traceId = data?.split(actionType)?.[1]?.replace('\n', '')?.trim();
    return traceId;
  }

  return null;
};
