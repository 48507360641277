import { ApolloClient, InMemoryCache, type InMemoryCacheConfig, type NormalizedCacheObject } from '@apollo/client';

import { type TOptional } from '@/types/common';

import makeApolloLink from './makeApolloLink';

export type TMakeApolloClientArgs = {
  cacheOptions?: TOptional<InMemoryCacheConfig>;
  isClientOnly?: boolean;
  withAuth?: boolean;
};

const makeApolloClient = (args?: TMakeApolloClientArgs): ApolloClient<NormalizedCacheObject> => {
  const { cacheOptions, isClientOnly, withAuth } = args || {};

  return new ApolloClient({
    cache: new InMemoryCache(cacheOptions),
    link: makeApolloLink({ credentials: 'same-origin' }, withAuth),
    ssrMode: !isClientOnly,
  });
};

export default makeApolloClient;
