import delay from '@/helpers/delay';

export class TimoutError extends Error {}

// Only first of alternativeValues is used

const limitTime = <V>(promise: Promise<V>, timeout: number, ...alternativeValues: Array<V>): Promise<V> =>
  timeout > 0
    ? new Promise((resolve, reject) => {
        let done: boolean;
        const ifCan =
          <A>(fn: (arg: A) => void) =>
          (arg: A) => {
            if (!done) {
              done = true;
              fn(arg);
            }
          };
        promise.then(ifCan(resolve), ifCan(reject));
        delay(timeout).then(
          ifCan(() => {
            // Here it would be nice to call promise.cancel()
            if (alternativeValues.length) resolve(alternativeValues[0]!);
            else reject(new TimoutError(`Timeout ${timeout} ms is expired`));
          }),
        );
      })
    : promise;

export default limitTime;
