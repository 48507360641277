import { ApolloLink, type DefaultContext, type HttpOptions, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { env } from '@/environment';
import { AppAuthenticator, TokenType } from '@/helpers/api/tokens';

const makeApolloLink = (linkOptions?: HttpOptions, withAuth?: boolean): ApolloLink => {
  const httpLink = createHttpLink({
    ...linkOptions,
    uri: `${env.GRAPHQL_URL}`,
  });

  if (!withAuth) return httpLink;

  return setContext(async (_, context: DefaultContext): Promise<DefaultContext> => {
    const token = await AppAuthenticator.getInstance().getFreshAccessToken({ tokenType: TokenType.guest });
    return token ? { ...context, headers: { ...context?.headers, Authorization: `bearer ${token}` } } : context;
  }).concat(httpLink);
};

export default makeApolloLink;
