const errorMessages = {
  400: 'The key you have entered is Expired or Inactive',
  401: 'Authorization error',
  404: 'The key you have entered is not found',
  409: 'The access key you have entered is already associated',
  500: 'System Interruption',
  default: 'The key you have entered is not valid',
};

// page ''|'VoyagePlanner.Summary'
export const mapAccessKeysErrorCodes = (statusCode, page = 'AccessKeys') => {
  const status = errorMessages[statusCode] ? statusCode : 'default';

  return {
    defaultMessage: errorMessages[status],
    id: status === 'default' ? `${page}.errorMessage` : `${page}.${status}ErrorMessage`,
  };
};
