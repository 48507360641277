import type { SettingsSlice } from '@/ducks/common/settings';

export type CoreRequestConfig = RequestInit & {
  logShortResponseData?: boolean;
  settings?: SettingsSlice;
  token?: string;
  url?: string;
};

export class HttpError extends Error {
  #response: Response;
  #request: Request;
  #config: CoreRequestConfig;
  #responseBody: unknown;

  constructor(response: Response, request: Request, config: CoreRequestConfig, responseBody: unknown) {
    super(`HttpError(status: ${response.status}, statusText: ${response.statusText})`);
    this.#response = response;
    this.#request = request;
    this.#config = config;
    this.#responseBody = responseBody;
  }

  get config() {
    return this.#config;
  }

  get response() {
    return this.#response;
  }

  get request() {
    return this.#request;
  }

  get responseBody() {
    return this.#responseBody;
  }
}
