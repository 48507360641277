import * as DTSdk from '@dynatrace/oneagent-sdk';

import { passTraceIdToErrorStream } from '@/infra/logs/dynatrace/link-with-kibana.mjs';

let instance: DTSdk.OneAgentSDK;

const createDTInstance = () => {
  const DTInstance = DTSdk.createInstance();
  //@ts-expect-error
  if (DTInstance.getCurrentState() !== DTSdk.SDKState.ACTIVE) {
    console.error('Dynatrace: SDK is not active!');
  }

  DTInstance.setLoggingCallbacks({
    error: (msg) => console.error('Dynatrace SDK error: ' + msg),
    warning: (msg) => console.error('Dynatrace SDK warning: ' + msg),
  });

  return DTInstance;
};

export const getDTInstance = () => {
  if (!instance) {
    instance = createDTInstance();
    return instance;
  }

  return instance;
};

export const getDTTraceId = () => {
  const traceContextInfo = getDTInstance()?.getTraceContextInfo();
  if (!traceContextInfo.isValid) {
    return '';
  }

  const traceId = traceContextInfo.traceid;
  passTraceIdToErrorStream(traceId);

  return traceId;
};
