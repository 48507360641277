import memoize from 'lodash/memoize';

import type { TEligibilityParams } from '@/infra/types/api';

import { checkReferralEligibility } from '@/helpers/api/app';

const keyResolver = ({ referralType, reservationId }: TEligibilityParams) => `${referralType}-${reservationId}`;

export const memoizedEligibilityUrl = memoize(async ({ referralType, reservationId }: TEligibilityParams) => {
  try {
    return await checkReferralEligibility({ referralType, reservationId });
  } catch (error) {
    const key = keyResolver({ referralType, reservationId });
    memoizedEligibilityUrl.cache.delete(key);
    throw new Error(`Unable to check eligibility: ${key}, ${String(error)}`);
  }
}, keyResolver);
