import type { AriaRole, ComponentPropsWithoutRef, HTMLAttributes } from 'react';
import React, { useMemo } from 'react';

import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { ReactSVG } from 'react-svg';

import type { TOptional } from '@/types/common';

// Avoid using the "svgClassName" property!
// If you change this property, ReactSVG will recreate an instance of the svg element and things like "transition" will not work.
// Avoid changing the "svgAttrs" or "svgClassName" properties! If you can't do without them at all, then they shouldn't change often.
// Avoid the direct using of ReactSVG component https://virginvoyages.atlassian.net/browse/MSH-103168

export type TSvgBaseAttrs = {
  ariaLabel?: string;
  className?: string;
  onClick?: () => void;
  role?: AriaRole;
  src: string;
  svgAttrs?: TOptional<HTMLAttributes<SVGSVGElement>>;
  svgClassName?: TOptional<string>;
  tabIndex?: TOptional<number>;
};

type TProps = ComponentPropsWithoutRef<typeof ReactSVG> & TSvgBaseAttrs;

const Svg = ({
  ariaLabel,
  className,
  onClick,
  role,
  src,
  svgAttrs,
  svgClassName,
  tabIndex,
  wrapper,
  ...restAttrs
}: TProps) => {
  // Add aria-hidden: true to svgAttrs
  const svgAttrsWithAriaHidden = useMemo(() => {
    return {
      ...svgAttrs,
      'aria-hidden': true,
    };
  }, [svgAttrs]);

  const beforeInjection = useMemo(
    () =>
      svgClassName || !isEmpty(svgAttrsWithAriaHidden)
        ? (svg: SVGSVGElement) => {
            if (svgClassName) svg.classList.add(svgClassName!);
            type TAttr = keyof HTMLAttributes<SVGSVGElement>;
            if (svgAttrsWithAriaHidden) {
              Object.keys(svgAttrsWithAriaHidden).forEach((name) =>
                svg.setAttribute(name, svgAttrsWithAriaHidden[name as TAttr]),
              );
            }
          }
        : undefined,
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [svgClassName, ...(svgAttrsWithAriaHidden ? Object.entries(svgAttrsWithAriaHidden).flat() : [])],
  );

  const roleValue = role || (onClick ? ('button' as const) : undefined);
  return (
    <ReactSVG
      aria-label={ariaLabel}
      beforeInjection={beforeInjection}
      className={cn('Svg', className)}
      evalScripts="always"
      onClick={onClick}
      onError={console.error}
      role={roleValue}
      src={src}
      tabIndex={tabIndex}
      wrapper={wrapper || 'span'}
      {...restAttrs}
    />
  );
};

export default Svg;
