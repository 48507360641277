import { JL } from 'jsnlog';
import get from 'lodash/get';
import { v4 as generateUUID } from 'uuid';

import { env } from '@/environment';
import { AppAuthenticator, TokenType } from '@/helpers/api/tokens';
import { getIsProdBuild } from '@/helpers/util/env';
import { getDTTraceId } from '@/infra/logs/dynatrace';

import { format } from './dateUtil';

export default function configureJsonLog(loggingServiceUrl, level) {
  const defaultBeforeSend = (xhr, json) => {
    // Modify the message of the log request to the server.
    for (let i = 0; i < json.lg.length; i += 1) {
      const msg = JSON.parse(json.lg[i].m);
      if (msg && msg.correlationId) {
        json.lg[i].componentName = 'BookVoyage-UI';
        json.lg[i].hostname = window.location.hostname;
        json.lg[i].offset = createOffset(new Date());
        json.lg[i].timestamp = getFormattedDate(); // new Date('30 July 2010 15:05 UTC').toISOString();
        json.lg[i].correlationId = msg.correlationId;
        json.lg[i].messageCode = msg.messageDetail.messageCode;
        json.lg[i].message = msg.messageDetail.message;
        json.lg[i].severity = msg.messageDetail.severity;
        json.lg[i].componentVersion = env.APP_VERSION;
        json.lg[i].messageDetail = JSON.stringify(msg.message);
        json.lg[i].deviceInfo = window.systemDetail;
        json.lg[i]['trace-id'] = getDTTraceId();
      } else {
        json.lg.splice(i, 1);
      }
    }

    xhr.originalSend = xhr.send;
    xhr.send = function (msg) {
      (async () => {
        const guestAccessToken = await AppAuthenticator.getInstance().getFreshAccessToken({
          tokenType: TokenType.guest,
        });

        xhr.setRequestHeader('CorrelationId', generateUUID());
        xhr.setRequestHeader('Authorization', `bearer ${guestAccessToken}`);

        msg = JSON.stringify(JSON.parse(msg).lg);
        xhr.msg = msg; // Store message in xhr
        xhr.originalSend(msg);
      })();
    };
    xhr.timeout = 10000;
  };
  const url = `${loggingServiceUrl}/logs`;
  const appender = JL.createAjaxAppender('cafkaAppender');
  appender.setOptions({
    batchSize: 1,
    bufferSize: 1,
    level: getLoggingLevel(level).severityCode,
    sendTimeout: 10000,
    sendWithBufferLevel: 5000,
    storeInBufferLevel: getLoggingLevel(level).severityCode,
    url,
  });
  JL.setOptions({
    corsAllowedOriginsRegex: url,
    defaultAjaxUrl: url,
    defaultBeforeSend,
    enabled: getIsProdBuild(),
    requestId: generateUUID(),
  });
  JL().setOptions({ appenders: [appender] });
}

export function log(error) {
  const correlationId = generateUUID();
  const messageDetail = {
    message: 'common error message',
    messageCode: '9001',
    severity: 'ERROR',
  };
  if (typeof error === 'string') {
    return JL('BV').info({
      correlationId: correlationId,
      message: error,
      messageDetail: messageDetail,
    });
  } else if (error instanceof Error) {
    let obj = {
      Details: error.stack,
      'Error Name': error.name,
      Message: error.message,
      'Time Stamp': new Date().toUTCString(),
    };
    return JL('BV').error({
      correlationId: correlationId,
      message: obj,
      messageDetail: messageDetail,
    });
  } else {
    return JL('BV').warn({
      correlationId: correlationId,
      message: error,
      messageDetail: messageDetail,
    });
  }
}

export function registerErrorLog() {
  window.onerror = function (msg, url, lineNo, columnNo, error) {
    const string = msg.toLowerCase();
    const substring = 'script error';
    if (string.indexOf(substring) > -1) {
      log('Script Error: See Browser Console for Detail');
    } else {
      const message = [
        'Message: ' + msg,
        'URL: ' + url,
        'Line: ' + lineNo,
        'Column: ' + columnNo,
        'Error object: ' + JSON.stringify(error),
      ].join(' - ');

      log(new Error(message));
    }

    return false;
  };
  window.onunhandledrejection = function (event) {
    log(new Error(get(event, 'reason', '')));
  };
}

export function getLoggingLevel(mode) {
  switch (mode) {
    case '0':
      return { severityCode: 1000, severityText: 'TRACE' }; //log TRACE
    case '1':
      return { severityCode: 2000, severityText: 'DEBUG' }; //log DEBUG
    case '2':
      return { severityCode: 3000, severityText: 'INFO' }; // log INFO
    case '3':
      return { severityCode: 4000, severityText: 'WARN' }; // WARN
    case '4':
      return { severityCode: 5000, severityText: 'ERROR' }; // ERROR
    case '5':
      return { severityCode: 6000, severityText: 'FATAL' }; // FATAL
  }
}

function pad(value) {
  return value < 10 ? '0' + value : value;
}
function createOffset(date) {
  const sign = date.getTimezoneOffset() > 0 ? '-' : '+';
  const offset = Math.abs(date.getTimezoneOffset());
  const hours = pad(Math.floor(offset / 60));
  const minutes = pad(offset % 60);
  return sign + hours + ':' + minutes;
}

function trimVersion(ix, version, majorVersion) {
  // trim the version string
  if ((ix = version.indexOf(';')) !== -1) {
    version = version.substring(0, ix);
  }
  if ((ix = version.indexOf(' ')) !== -1) {
    version = version.substring(0, ix);
  }
  if ((ix = version.indexOf(')')) !== -1) {
    version = version.substring(0, ix);
  }

  majorVersion = parseInt('' + version, 10);
  if (isNaN(majorVersion)) {
    version = '' + parseFloat(navigator.appVersion);
  }
  return version;
}

function getBrowser(verOffset, nAgt, browser, version, nameOffset, ix, majorVersion) {
  // Opera
  if ((verOffset = nAgt.indexOf('Opera')) !== -1) {
    browser = 'Opera';
    version = nAgt.substring(verOffset + 6);
    if ((verOffset = nAgt.indexOf('Version')) !== -1) {
      version = nAgt.substring(verOffset + 8);
    }
  }
  // Opera Next
  if ((verOffset = nAgt.indexOf('OPR')) !== -1) {
    browser = 'Opera';
    version = nAgt.substring(verOffset + 4); // Edge
  } else if ((verOffset = nAgt.indexOf('Edge')) !== -1) {
    browser = 'Microsoft Edge';
    version = nAgt.substring(verOffset + 5); // MSIE
  } else if ((verOffset = nAgt.indexOf('MSIE')) !== -1) {
    browser = 'Microsoft Internet Explorer';
    version = nAgt.substring(verOffset + 5); // Chrome
  } else if ((verOffset = nAgt.indexOf('Chrome')) !== -1) {
    browser = 'Chrome';
    version = nAgt.substring(verOffset + 7); // Safari
  } else if ((verOffset = nAgt.indexOf('Safari')) !== -1) {
    browser = 'Safari';
    version = nAgt.substring(verOffset + 7);
    if ((verOffset = nAgt.indexOf('Version')) !== -1) {
      version = nAgt.substring(verOffset + 8); // Firefox;
    }
  } else if ((verOffset = nAgt.indexOf('Firefox')) !== -1) {
    browser = 'Firefox';
    version = nAgt.substring(verOffset + 8); // MSIE 11+
  } else if (nAgt.indexOf('Trident/') !== -1) {
    browser = 'Microsoft Internet Explorer';
    version = nAgt.substring(nAgt.indexOf('rv:') + 3); // Other browsers
  } else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
    browser = nAgt.substring(nameOffset, verOffset);
    version = nAgt.substring(verOffset + 1);
    if (browser.toLowerCase() === browser.toUpperCase()) {
      browser = navigator.appName;
    }
  }

  version = trimVersion(ix, version, majorVersion);

  return browser + ' ' + version;
}

function getDevice(nAgt) {
  // mobile version
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(nAgt)) {
    return 'mobile';
  }
  return 'desktop';
}

function getOS(unknown, nAgt, nVer) {
  let os = unknown;
  const clientStrings = [
    {
      r: /(Windows 10.0|Windows NT 10.0)/,
      s: 'Windows 10',
    },
    {
      r: /(Windows 8.1|Windows NT 6.3)/,
      s: 'Windows 8.1',
    },
    {
      r: /(Windows 8|Windows NT 6.2)/,
      s: 'Windows 8',
    },
    {
      r: /(Windows 7|Windows NT 6.1)/,
      s: 'Windows 7',
    },
    {
      r: /Windows NT 6.0/,
      s: 'Windows Vista',
    },
    {
      r: /Windows NT 5.2/,
      s: 'Windows Server 2003',
    },
    {
      r: /(Windows NT 5.1|Windows XP)/,
      s: 'Windows XP',
    },
    {
      r: /(Windows NT 5.0|Windows 2000)/,
      s: 'Windows 2000',
    },
    {
      r: /(Win 9x 4.90|Windows ME)/,
      s: 'Windows ME',
    },
    {
      r: /(Windows 98|Win98)/,
      s: 'Windows 98',
    },
    {
      r: /(Windows 95|Win95|Windows_95)/,
      s: 'Windows 95',
    },
    {
      r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/,
      s: 'Windows NT 4.0',
    },
    {
      r: /Windows CE/,
      s: 'Windows CE',
    },
    {
      r: /Win16/,
      s: 'Windows 3.11',
    },
    {
      r: /Android/,
      s: 'Android',
    },
    {
      r: /OpenBSD/,
      s: 'Open BSD',
    },
    {
      r: /SunOS/,
      s: 'Sun OS',
    },
    {
      r: /(Linux|X11)/,
      s: 'Linux',
    },
    {
      r: /(iPhone|iPad|iPod)/,
      s: 'iOS',
    },
    {
      r: /Mac OS X/,
      s: 'Mac OS X',
    },
    {
      r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/,
      s: 'Mac OS',
    },
    {
      r: /QNX/,
      s: 'QNX',
    },
    {
      r: /UNIX/,
      s: 'UNIX',
    },
    {
      r: /BeOS/,
      s: 'BeOS',
    },
    {
      r: /OS\/2/,
      s: 'OS/2',
    },
    {
      r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/,
      s: 'Search Bot',
    },
  ];
  for (const id in clientStrings) {
    if (Object.hasOwn(clientStrings, id)) {
      const cs = clientStrings[id];
      if (cs.r.test(nAgt)) {
        os = cs.s;
        break;
      }
    }
  }

  let osVersion = unknown;

  try {
    if (/Windows/.test(os)) {
      osVersion = /Windows (.*)/.exec(os)[1];
      os = 'Windows';
    }

    switch (os) {
      case 'Mac OS X':
        osVersion = /Mac OS X ([._\d]+)/.exec(nAgt)[1];
        break;

      case 'Android':
        osVersion = /Android ([._\d]+)/.exec(nAgt)[1];
        break;

      case 'iOS':
        if (nVer) {
          osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
          osVersion = osVersion[1] + '.' + osVersion[2] + '.' + (osVersion[3] | 0);
        }
        break;
    }
  } catch (e) {
    // Catch introduced to prevent code not loading if we can't identify OS
    // See MSH-50317 for more information
  }
  return { os: os, osVersion: osVersion };
}

export function setSystemDetails() {
  let unknown = '-';
  let nVer = navigator.appVersion;
  let nAgt = navigator.userAgent;
  let browser = navigator.appName;
  let version = '' + parseFloat(navigator.appVersion);
  let majorVersion = parseInt(navigator.appVersion, 10);
  let nameOffset, verOffset, ix;
  let osData = getOS(unknown, nAgt, nVer);
  let device = getDevice(nAgt);
  let name = getBrowser(verOffset, nAgt, browser, version, nameOffset, ix, majorVersion);

  window.systemDetail = {
    name: name,
    operatingSystem: osData.os,
    osVersion: osData.osVersion,
    type: device,
  };
  return {
    name: name,
    operatingSystem: osData.os,
    osVersion: osData.osVersion,
    type: device,
  };
}

const getFormattedDate = (date = new Date()) => format(date, "yyyy-MM-dd'T'HH:mm:ss.SS");

export const initLogger = () => {
  configureJsonLog(env.REST_BFF_URL, env.LOGGING_LEVEL);
  setSystemDetails();
  registerErrorLog();
};
